import { useCallback, useEffect, useState } from "react";

import { ConfigurationProvider } from "../../configuration/ConfigurationProvider";
import { HttpClient } from "../../http/HttpClient";
import { N4UPermissions } from "../Permissions";
import { Navigate } from "react-router-dom";
import { SplashScreen } from "../../../areas/common/splash-screen/SplashScreen";
import { UrlUtility } from "../../../utils/UrlUtility";
import { useIsAuthenticated } from "@azure/msal-react";
import { useSetPermissions } from "../hooks/usePermissions";

interface IRequireAuthProps {
  children: React.ReactNode;
}

export function RequireAuth(props: IRequireAuthProps) {
  const isAuthenticated = useIsAuthenticated();
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const setPermissions = useSetPermissions();

  const handleGateResponse = useCallback(
    (response) => {
      if (response) {
        setIsValid(true);
        if (response.isGlobalManagement) {
          setPermissions([{ id: "" + N4UPermissions.MANAGER }]);
          return; 
        }
        if (response.isApprover) {
          setPermissions([{ id: "" + N4UPermissions.APPROVER }]);
        }
      } else {
        setIsValid(false);
        setPermissions([]);
      }
    },
    [setPermissions, setIsValid]
  );

  useEffect(() => {
    if (isAuthenticated) {
      HttpClient.sessionRequest({
        url: `${UrlUtility.join(ConfigurationProvider.getConfiguration().App.BackendUrl, "/api/v1/gate")}`,
        method: "GET",
      })
        .then((res) => handleGateResponse(res.data))
        .catch((_) => handleGateResponse(null))
        .then((_) => setIsLoading(false));
    }
  }, [isAuthenticated]);

  if (!isAuthenticated) {
    return <Navigate to="/identity/login" />;
  }

  if (isLoading) {
    return <SplashScreen />;
  }

  if (!isValid) {
    return <Navigate to="/identity/sessionExpired" />;
  }

  return <>{props.children}</>;
}
